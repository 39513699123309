import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ItemDescription from "./ItemDescription";
import ImageLoading from "./ImageView";
import useScreenHeight from "../../hooks/useScreenHeight";
import VideoPlayer from "./VideoPlayer";

const MenuItem = ({ toggleItemInBasket, likedItems, current_menu, scrollToItemIndex, index, isActive }) => {
  const availableItems = current_menu.items.filter((item) => item.is_item_available);
  const screenHeight = useScreenHeight();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const menuIndex = index;
  const playerRefs = useRef([]);

  const handleSlideChange = (swiper) => {
    setCurrentItemIndex(swiper.activeIndex);
  };
  const swiperSettings = {
    direction: "vertical",
    slidesPerView: "auto",
    spaceBetween: 0,
    pagination: {
      clickable: true,
    },
    onSlideChange: handleSlideChange,
    initialSlide: scrollToItemIndex,
    speed: 400,
  };

  const verticalSwiperStyle = {
    height: `${screenHeight}px`,
    width: "100vw",
    overflow: "hidden",
  };

  return (
    <Swiper className="menu-item-slider" direction={"vertical"} {...swiperSettings} style={verticalSwiperStyle}>
      {availableItems.map((item, index) => (
        <SwiperSlide key={index}>
          {item.item_video.url ? (
           <VideoPlayer playerRefs={playerRefs} isActive={isActive} currentItemIndex={currentItemIndex} index={index} item={item} />
          ) : (
            <ImageLoading src={item.content_image.url} />
          )}
          <ItemDescription
            menuIndex={menuIndex}
            itemIndex={currentItemIndex}
            likedItems={likedItems}
            currentMenu={current_menu}
            item={item}
            index={index}
            categoryName={current_menu.primary.section_title[0].text}
            toggleItemInBasket={toggleItemInBasket}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default MenuItem;
