import React from "react";

const HeartIcon = ({ fill }) => {
  return (
    <svg width="35" height="32" viewBox="0 0 35 30" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_2)">
        <path
          d="M16.6475 6.0024C16.8169 6.34118 17.1631 6.55519 17.5419 6.55519C17.9207 6.55519 18.267 6.34118 18.4363 6.0024C18.9579 4.95937 20.3458 3.27134 22.3159 2.14559C24.2498 1.04053 26.6789 0.509073 29.3929 1.5947C32.0725 2.66652 33.3904 4.77262 33.6594 7.19342C33.9334 9.65938 33.1095 12.4655 31.4088 14.7331C29.6334 17.1004 26.0278 20.7119 22.8107 23.7758C21.213 25.2973 19.7289 26.6673 18.644 27.657C18.2046 28.0579 17.8309 28.3962 17.5419 28.6567C17.2529 28.3962 16.8792 28.0579 16.4398 27.657C15.3549 26.6673 13.8708 25.2973 12.2732 23.7758C9.05606 20.7119 5.45048 17.1004 3.67503 14.7331C0.329677 10.2726 1.52188 3.75121 6.91314 1.5947C9.63474 0.506062 11.7348 1.05226 13.3205 2.1094C14.9585 3.20141 16.0816 4.87056 16.6475 6.0024Z"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      {/* <defs>
        <clipPath id="clip0_1_2">
          <rect width="35" height="30" fill="white" />
        </clipPath>
      </defs> */}
    </svg>
  );
};

export default HeartIcon;
