import React from "react";
import BasketItem from "./BasketItem";
import FooterCopy from "../ListView/components/FooterCopy";
import LineBreak from "../ListView/components/LineBreak";
import Navbar from "../MenuView/NavBars/MenuTopNavbar";
import CreatedBy from "../LoadingScreen/Components/CreatedBy";

const Basket = ({ items = [], onClose, onRemove, onChange, setScrollToItemIndex }) => {
  const goToIndex = (menuIndex, itemIndex) => {
//    console.log("goToIndex", menuIndex, itemIndex);

    onClose();
    onChange(menuIndex);
    setScrollToItemIndex(itemIndex);
  };

  return (
    <div className="basket-page-container noselect">
      <Navbar onClose={onClose} pageView={"basket"} />
      <div className="content-wrapper non-scrollable">
        <div className="list-view">
          <div className="top-padding-70" />
          {items.length > 0 ? (
            <div className="items-in-basket-view">
              <div className="list-view-section">
                {items.map((item, index) => (
                  <div key={index} className="menu-section">
                    <BasketItem item={item} onRemove={onRemove} goToIndex={goToIndex} index={index} />
                  </div>
                ))}
              </div>
              <div className="footer-section">
                <LineBreak />
                <FooterCopy />
              </div>
            </div>
          ) : (
            <div>
              <LineBreak />
              <p className="empty-basket-copy">Save items to your basket using the heart button</p>
            </div>
          )}
        </div>
      </div>
      <CreatedBy />
    </div>
  );
};

export default Basket;
