import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Menu from "./components/MenuView/MenuCatagoryView";
import Basket from "./components/Basket/Basket";
import { useSinglePrismicDocument } from "@prismicio/react";
import TopNavbar from "./components/MenuView/NavBars/MenuTopNavbar";
import ListView from "./components/ListView/ListView";
import usePinchGesture from "./hooks/pinchChecking";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import "./index.css";
import useBasket from "./hooks/useBasket";

export const MenuApp = ({ currentConfig }) => {
  const [menuViewState, setMenuViewState] = useState(true);
  const [basketVisible, setBasketVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollToListViewIndex, setScrollToListViewIndex] = useState(0);
  const [scrollToItemIndex, setScrollToItemIndex] = useState(null);
  const [menu, menuState] = useSinglePrismicDocument(currentConfig.prismicDocument);
  const notFound = menuState.state === "failed";
  const { likedItems, basketItems, toggleItemInBasket, removeFromBasket } = useBasket();

  const toggleBasketVisibility = () => {
    setBasketVisible((prevVisibility) => !prevVisibility);
  };
  const toggleMenuView = () => {
    setMenuViewState((prevVisibility) => !prevVisibility);
  };

  usePinchGesture(toggleMenuView);


  useEffect(() => {
    if (isLoading & (menuState.state === "loaded") || menuState.state === "failed") {
      const timer = setTimeout(() => {
        setIsLoading(false);
        setMenuViewState(true);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [isLoading, menuState.state]);

  
  if (menu) {
    return (
      <TransitionGroup>
        <div className="spacer zero-z"> </div>
        <TopNavbar
          menu={menu.data.body}
          activeIndex={activeIndex}
          onBasketClick={toggleBasketVisibility}
          menuSwapView={toggleMenuView}
          setScrollToListViewIndex={setScrollToListViewIndex}
          onClose={() => setMenuViewState(false)}
          pageView={ 
            basketVisible
            ? "basket"
            : menuViewState
            ? "list"
            : "item"}
        />
        {isLoading && (
          <CSSTransition timeout={200} classNames="fade" key="loading">
            <LoadingScreen />
          </CSSTransition>
        )}
        {basketVisible ? (
          <CSSTransition timeout={200} classNames="fade" key="basket">
            <Basket items={basketItems} onClose={toggleBasketVisibility} onRemove={removeFromBasket} onChange={setActiveIndex} setScrollToItemIndex={setScrollToItemIndex} />
          </CSSTransition>
        ) : menuViewState ? (
          <CSSTransition timeout={100} classNames="fade" key="listView">
            <section className="list-view-component-container ">
            <ListView
              menu={menu.data.body}
              onClose={() => setMenuViewState(false)}
              onChange={setActiveIndex}
              setScrollToItemIndex={setScrollToItemIndex}
              scrollToListViewIndex={scrollToListViewIndex}
            />
            </section>
          </CSSTransition>
        ) : (
          <CSSTransition timeout={0} classNames="fade" key="menu">
            <section>
              <Menu likedItems={likedItems} menu={menu.data.body} activeIndex={activeIndex} onChange={setActiveIndex} toggleItemInBasket={toggleItemInBasket} scrollToItemIndex={scrollToItemIndex} />
            </section>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  } else if (notFound) {
    return console.log("response notfound :( ");
  }
  return null;
};

export default MenuApp;
