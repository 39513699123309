import mixpanel from "mixpanel-browser";
import React, { useEffect, useRef } from "react";
import ListViewImage from "./components/ListViewImage";
import SectionHeader from "./components/SectionHeader";
import ListViewItem from "./components/ListViewItem";
import FooterCopy from "./components/FooterCopy";
import LineBreak from "./components/LineBreak";
import CreatedBy from "../LoadingScreen/Components/CreatedBy";

const ListView = ({
  menu,
  onClose,
  onChange,
  setScrollToItemIndex,
  scrollToListViewIndex = 3,
}) => {
  const categoryRefs = useRef([]);
  const listViewRef = useRef(null);

  useEffect(() => {
    if (categoryRefs.current[scrollToListViewIndex]) {
      categoryRefs.current[scrollToListViewIndex].scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  }, [scrollToListViewIndex]);

  const goToIndex = (catIndex, itemIndex) => {
    mixpanel.track("Item View", {
      Item: menu[catIndex].items[itemIndex].title[0].text,
      Category: menu[catIndex].primary.section_title[0].text,
    });
    onClose();
    onChange(catIndex);
    setScrollToItemIndex(itemIndex);
  };


  useEffect(() => {
    const preventPinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault(); // Prevents pinch-zoom
        e.preventDefault(); // Prevents gesture events (iOS Safari)
      }
    };
  
    const preventGesture = (e) => {
      e.preventDefault(); // Prevents gesture events (iOS Safari)
    };
  
    // Add touch event listeners to the document
    document.addEventListener('touchstart', preventPinchZoom, { passive: false });
    document.addEventListener('touchmove', preventPinchZoom, { passive: false });
  
    // Add gesture event listeners for iOS devices
    document.addEventListener('gesturestart', preventGesture, { passive: false });
    document.addEventListener('gesturechange', preventGesture, { passive: false });
    document.addEventListener('gestureend', preventGesture, { passive: false });
  
    return () => {
      // Remove touch event listeners
      document.removeEventListener('touchstart', preventPinchZoom);
      document.removeEventListener('touchmove', preventPinchZoom);
  
      // Remove gesture event listeners
      document.removeEventListener('gesturestart', preventGesture);
      document.removeEventListener('gesturechange', preventGesture);
      document.removeEventListener('gestureend', preventGesture);
    };
  }, []);

  return (
    <div>
      <div className="list-view noselect" ref={listViewRef}>
        {menu.map((category, catIndex) => (
          <div
            key={catIndex}
            ref={(el) => (categoryRefs.current[catIndex] = el)}
            className="list-view-section"
          >
            <SectionHeader
              icon={category.primary.icon}
              sectionTitle={category.primary.section_title[0].text}
            />
            {category.items.map((item, itemIndex) => (
              <div key={itemIndex} className="option-container">
                <div
                  className="option"
                  onClick={() => goToIndex(catIndex, itemIndex)}
                >
                  <ListViewImage src={item.content_image.url} />
                  <ListViewItem item={item} showPriceAndChevron={true} />
                </div>
              </div>
            ))}
            <LineBreak />
          </div>
        ))}
        <div className="spacer-300"> </div>
        <FooterCopy className="noselect" />
        <CreatedBy />
      </div>
    </div>
  );
};

export default ListView;